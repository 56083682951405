<!-- You can custom the "myQuillEditor" name used to find the quill instance in current component -->
<template>
<div>
    <h5>header element</h5>
   <quill-editor v-model="content"
                ref="myQuillEditor"
                :options="editorOption"
                >
  </quill-editor>
  <p  v-html="content"></p>
  <!-- Or manually control the data synchronization（或手动控制数据流） -->
  <!-- <quill-editor :content="content"
                :options="editorOption"
                @change="onEditorChange($event)">
  </quill-editor> -->
</div>
 
</template>
 
<script>

  export default {
    data () {
      return {
        content: '',
        editorOption: {
          toolbar: false
        }
      }
    },

    methods: {
      onEditorBlur(quill) {
        console.log('editor blur!', quill)
      },
      onEditorFocus(quill) {
        console.log('editor focus!', quill)
      },
      onEditorReady(quill) {
        console.log('editor ready!', quill)
      },
      onEditorChange({ quill, html, text }) {
        console.log('editor change!', quill, html, text)
        this.content = html
      }
    },
    computed: {
      editor() {
        return this.$refs.myQuillEditor.quill
      }
    },
    mounted() {
    }
  }
</script>